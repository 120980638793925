/** 
 *   新品
 */
import { get } from '@/untils/js/axios.js'

// 获得新品分类
export const getNewSaleClassify = params => get(`/goods/columns-type/2`, params)

// 获得新品分类下商品
export const getNewSaleClassifyGoods = (id,params) => get(`/goods/columns-type-goods/${id}`, params)

// 获得新品Banner
export const getNewSaleBanner = (id,params) => get(`/banner/${id}`, params)

// 获得新品标题
export const getNewSaleTitle = params => get(`/goods/columns-topping-type`, params)